import React from "react";

export default ({ color }) => (
  <svg viewBox="0 0 19 26">
    <path
      fill={color}
      d="M0.2,9.5c0-5.1,4.2-9.3,9.3-9.3c5.1,0,9.3,4.2,9.3,9.3c0,6.3-8.3,15.7-8.7,16.1c-0.3,0.4-0.9,0.4-1.2,0
	C8.5,25.1,0.2,15.8,0.2,9.5z M1.9,9.5c0,4.4,4.8,10.9,7.6,14.2c2.8-3.3,7.6-9.9,7.6-14.2c0-4.2-3.4-7.6-7.6-7.6
	C5.3,1.9,1.9,5.3,1.9,9.5z M4.8,9.5c0-2.6,2.1-4.7,4.7-4.7c2.6,0,4.7,2.1,4.7,4.7c0,2.6-2.1,4.7-4.7,4.7C6.9,14.1,4.8,12,4.8,9.5z
	 M6.5,9.5c0,1.7,1.3,3,3,3c1.7,0,3-1.3,3-3c0-1.7-1.3-3-3-3C7.8,6.5,6.5,7.8,6.5,9.5z"
    />
  </svg>
);
